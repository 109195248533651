* {
    text-wrap: balance;
}
.flex-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;

}
body {
    scroll-behavior: smooth;
}
.flex-grow {
    flex-grow: 1;
}
.full-width {
    width: 100%;
}
.graph{
    max-width: 62em;
    min-height: 35em;
}
.flex-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
}
* {
    font-family: 'Roboto';
    text-wrap: balance;
    word-wrap: break-word;
}
a {
    all: unset
}

.profileForm {
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr;
}
.doubleColumn {
    grid-column-start: 1;
    grid-column-end: 3;
}
.chatWindow{
    overflow-y: auto;
    max-height: 80vh;
    max-width: 60em;
}
.userMessage {
    background-color: hsl(247.66deg 60.69% 46.16% / 97%) !important;
    color: white !important
}
.assistantMessage {
    
}
.small-gap{
    gap: 1em
}
.position-relative{
    position: relative
}
main{
    margin: 2em;
}
